import { backgroundColor, container, title } from 'assets/jss/material-kit-react.jsx'

import imagesStyle from 'assets/jss/material-kit-react/imagesStyles.jsx'

const postStyle = theme => ({

  backgroundColor: {
    ...backgroundColor,
  },
  container: {
    zIndex: '12',
    ...container,

    [theme.breakpoints.down('sm')]: {

      maxWidth: '100%',
      width: '100%',
    },
  },
  name: {
    marginTop: '-80px',
  },
  ...imagesStyle,
  main: {
    position: 'relative',
    zIndex: '3',
  },
  mainRaised: {
    margin: '-20px  0px',
  },
  title: {
    ...title,
    display: 'inline-block',
    position: 'relative',
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none',
    color: '#fff',
    textAlign: 'center',
  },
  socials: {
    marginTop: '0',
    width: '100%',
    transform: 'none',
    left: '0',
    top: '0',
    height: '100%',
    lineHeight: '41px',
    fontSize: '20px',
    color: '#999',
  },
  navWrapper: {
    margin: '20px auto 50px auto',
    textAlign: 'center',
  },
})

export default postStyle
