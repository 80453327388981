import { H2, H3, imageDarkenOnHover, P, title } from 'assets/jss/material-kit-react.jsx'


const recentBlogPostsSkipStyle = {
  imageDarkenOnHover,
  itemGrid: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  mainWrapper: {
    marginRight: -50,
    marginLeft: -50,
    display: 'flex',

    flexDirection: 'row',

    alignItems: 'flex-start',
    width: '100%',
    maxWidth: '100%',
    '@media (max-width: 1060px)': {
      flexDirection: 'column',
      alignItems: 'center',
      margin: 0,
    },
  },

  bigImage: {
    marginBottom: 20,
    height: 514,
    width: 685,
  },
  bigArticleContent: {

    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  linkClass: {

    height: 158,

    width: 210,
    minHeight: 158,
    minWidth: 210,
    maxHeight: 158,
    maxWidth: 210,
    marginRight: 20,
    overflow: 'hidden',


  },
  smallImage: {
    minHeight: 158,
    minWidth: 210,


  },
  smallArticleContent: {
    display: 'flex',

    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  H3: {
  ...H3,
  },
  H2: {
    ...H2,
    textAlign: 'left',
    color: '#2b2b2b',
  },
  P: {
    ...P,
    textAlign: 'left',
    padding: '20px 0',
    color: '#2b2b2b',
  },

  smallDate: {
    fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
    margin: 0,
    padding: 0,
    fontSize: 18,
    color: '#2b2b2b',
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 1,
  },
  firstWrapper: {
    width: '56%',
    marginRight: 50,
    '@media (max-width: 1060px)': {
      width: 685,
      maxWidth: '100%',
      paddingBottom: 50,
      margin: 0,
    },
  },
  secondWrapper: {
    textAlign: 'left',

    width: '44%',
    '@media (max-width: 1060px)': {
      width: 685,
      display: 'flex',
      maxWidth: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  smallAricleWrapper: {

    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    paddingBottom: 20,
  },

  itemWrapper: {
    display: 'flex',
    flex: 1,
    height: 440,
    width: 580,
    maxWidth: 580,
    maxHeight: 400,
    overflow: 'hidden',
    paddingBottom: 20,
    '@media (max-width: 1060px)': {
      maxWidth: '100%',
      width: '100%',
      float: 'none',
      margin: 0,
      paddingBottom: 34,
    },
  },
  wrapper: {

    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    maxWidth: '100%',
    '@media (max-width: 1060px)': {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      marginRight: 0,
    },


  },
  sectionTitleWrapper: {

    textAlign: 'center',
    fontWeight: 500,
    color: '#1a1a1a',
    margin: 0,
    padding: '0 0 80px',
  },
  sectionTitleTop: {
    margin: 0,
    padding: 0,
    fontFamily: '\'Poppins\', sans-serif',
    fontSize: 12,
    textTransform: 'uppercase',
    lineHeight: 1.0,
    letterSpacing: '3px',
  },
  sectionTitleBottom: {
    fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
    fontSize: 48,

    lineHeight: 1.06,
    letterSpacing: '-1px',
  },

  header: {
    margin: 0,
    paddingBottom: 15,
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: 1.06,
    letterSpacing: '-1px',
  },


  textCenter: {
    textAlign: 'center',
  },
  title: {
    ...title,
    marginBottom: '1rem',
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none',
  },
  description: {
    color: '#999',
  },
  galleryItem: {
    flex: 1,
    backgroundColor: '#f2f2f2',
  },
  paper: {

    textAlign: 'center',
    color: '#000',

    height: 140,
    width: 100,
  },
}

export default recentBlogPostsSkipStyle
