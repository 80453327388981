import React from 'react'
import { graphql } from 'gatsby'

import Footer from 'components/Footer/Footer.jsx'

import withStyles from '@material-ui/core/styles/withStyles'

import postStyle from './postStyle'
import PageHeader from '../../components/PageHeader/PageHeader'
import RecentBlogPosts from '../../elements/RecentBlogPosts/RecentBlogPosts'
import Content from '../../elements/Content/Content'
import Header from '../../components/Header/Header'
import Section from '../../components/Section/Section'
import InstagramSection from '../../components/InstagramSection/InstagramSection'
import SpainHolidaysCta from '../../elements/SpainholidaysCta/SpainHolidaysCta'
import RecentBlogPostsSkip from '../../elements/RecentBlogPostsSkip/RecentBlogPostsSkip'

class PostTemplate extends React.Component {
  render() {
    const { classes, data } = this.props
    const { artikelbild, titel, untertitel, slug, meta } = data.datoCmsArtikel

    return (
      <div className={classes.backgroundColor}>
        <Header
          SEOtitle={titel}
          SEOdescription={untertitel}
          SEOimage={artikelbild.fluid.src}
          SEOpathname={slug}
          SEOarticle
        />

        <PageHeader
          big
          image={artikelbild.fluid}
          title={titel}
          subtitle={untertitel}
          date={meta.updatedAt}
        />
        <Section
          paddingTop={0}
          paddingBottom={60}
          innerStyles={{ maxWidth: 705 }}
        >
          <Content content={data.datoCmsArtikel.content} />
        </Section>
        <RecentBlogPostsSkip articles={data.allDatoCmsArtikel.nodes}/>
        <InstagramSection />
        <SpainHolidaysCta
          title={data.datoCmsHome.spainholidayBanner[0].title}
          subtitle={data.datoCmsHome.spainholidayBanner[0].subtitle}
          buttonTitle={data.datoCmsHome.spainholidayBanner[0].buttonTitle}
          buttonLink={data.datoCmsHome.spainholidayBanner[0].buttonLink}
        />
        <Footer />
      </div>
    )
  }
}

export const query = graphql`
  query($slug: String!) {
    allDatoCmsArtikel(
      filter:{
        slug:{ne:$slug}
        meta:{status:{eq:"published"}  }}
    ){
      nodes{
        slug
        titel
        meta{
          updatedAt(formatString: "MM-DD-YYYY")
        }
        inhaltsausschnitt
        artikelbild{
          alt
          fluid(maxWidth:705){
            src
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
    }
    datoCmsArtikel(slug: { eq: $slug }) {
      id
      artikelbild {
        fluid {
          src
        }
      }
      titel
      untertitel
      slug
      meta {
        updatedAt(formatString: "MM-DD-YYYY")
      }
      content {
        __typename
        ... on DatoCmsH2 {
          heading
        }
        ... on DatoCmsH3 {
          heading
        }
        ... on DatoCmsH4 {
          heading
        }
        ... on DatoCmsH5 {
          heading
        }
        ... on DatoCmsH6 {
          heading
        }
        ... on DatoCmsText {
          text
        }
        ... on DatoCmsYoutubeVideo {
          link
        }
        ... on DatoCmsImage {
          image {
            alt
            fluid(maxWidth: 705) {
              src
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
      }
    }
    datoCmsHome {
      spainholidayBanner {
        title
        subtitle
        buttonLink
        buttonTitle
      }
    }
  }
`

export default withStyles(postStyle)(PostTemplate)
