import { container, H1 } from 'assets/jss/material-kit-react.jsx'


const pageHeaderStyle = {

  root: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#fff',
    alignItems: 'center',
  },
  big: {
    minHeight: 680,
    maxHeight: 680,
    '@media (max-width: 768px)': {
      minHeight: 300,
      maxHeight: 300,
    },
  },
  small: {
    minHeight: 550,
    maxHeight: 550,
    '@media (max-width: 768px)': {
      minHeight: 300,
      maxHeight: 300,
    },
  },
  imageWrapper: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 80,
  },
  bigWrapper: {
    maxWidth: 1500,
  },
  smallWrapper: {
    maxWidth: 1210,
  },
  H1: {
    ...H1,
    paddingBottom: 40,
    textTransform: 'none',
  },
  subtitle: {

    // font: 'italic bold 12px/30px "Cormorant Garamond", serif',
    fontFamily: '"Cormorant Garamond", sans-serif',
    margin: 0,
    fontSize: 28,
    color: '#2b2b2b',
    textSizeAdjust: '100%',
    fontStyle: 'italic',
    fontWeight: 'light',
    lineHeight: 1.4,
    letterSpacing: 0,
    paddingBottom: 40,
    verticalAlign: 'baseline',
    '@media (max-width: 768px)': {
      fontSize: 25,
    },
  },
  date: {
    fontFamily: '"Cormorant Garamond", sans-serif',
    margin: 0,
    fontSize: 24,
    color: '#1a1a1a',
    fontWeight: 'normal',
    lineHeight: 1.33,
    '@media (max-width: 768px)': {
      fontSize: 20,
    },
  },


  parallax: {
    height: '100vh',
    // height: '90vh',
    maxHeight: '1000px',
    overflow: 'hidden',
    position: 'relative',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    margin: '0',
    padding: '0',
    border: '0',
    display: 'flex',
    alignItems: 'center',
  },
  filter: {
    '&:before': {
      // background: 'rgba(159, 159, 159, 0.1)',
      // background: 'rgba(0, 0, 0, 0.09)',
      // background: 'rgba(215, 215, 215, 0.2)',
      // background: 'rgba(192, 197, 210, 0.12)',
    },
    '&:after,&:before': {
      position: 'absolute',
      zIndex: '1',
      width: '100%',
      height: '100%',
      display: 'block',
      left: '0',
      top: '0',
      content: '\'\'',
    },
  },

  container: {
    zIndex: '12',
    color: '#FFFFFF',
    ...container,
  },
  centered: {
    justifyContent: 'center',
    textAlign: 'center',
  },
}

export default pageHeaderStyle
